
<template>
    <!--<h1>AtlasTaxi</h1>-->
    <img alt="Vue logo" src="@/assets/homepage.png"/>
    
  </template>
  <script>
    export default {
      data() {
        return {
         // mainProps: { blank: true, width: 75, height: 75, class: 'm1' }
        }
      }
    }
  </script>