
<template>
 
    <img alt="Vue logo" width="200" height="200" src="@/assets/warning.png"/>
       <h1>Payment Not Completed</h1>
       <p>We were unable to complete the payment.</p>
  </template>
  <script>
    export default {
      data() {
        return {
         // mainProps: { blank: true, width: 75, height: 75, class: 'm1' }
        }
      }
    }
  </script>