import { createWebHistory, createRouter } from "vue-router";
import Completed from "../views/Completed.vue";
import NotCompleted from "@/views/NotCompleted.vue";
import Home from "@/views/Home.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/completed",
    name: "Completed",
    component: Completed,
  },
  {
    path: "/notcompleted",
    name: "NotCompleted",
    component: NotCompleted,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;